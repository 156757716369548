.header {
  border: 1px solid blue;
  background-color: aqua;
  a.active {
    border: 1px solid blue;
    background-color: blue;
    color: white;
    padding: 1px 3px;
  }
}
